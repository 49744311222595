import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Toolbar  from 'primevue/toolbar'
import Toast  from 'primevue/toast'
import Column  from 'primevue/column'
import ColumnGroup   from 'primevue/columngroup'
import Row   from 'primevue/row'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import RadioButton from 'primevue/radiobutton'
import InputNumber from 'primevue/inputnumber'
import Textarea from 'primevue/textarea'
import FileUpload from 'primevue/fileupload'
import Rating from 'primevue/rating'
import Datatable from 'primevue/datatable'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmDialog from 'primevue/confirmdialog'
export default defineNuxtPlugin((nuxtApp) => {
    // Doing something with nuxtApp
    nuxtApp.vueApp.use(PrimeVue, {ripple: true})
    nuxtApp.vueApp.component('Button', Button)
    nuxtApp.vueApp.component('ConfirmPopup', ConfirmPopup)
    nuxtApp.vueApp.component('InputText', InputText)
    nuxtApp.vueApp.component('Toolbar', Toolbar )
    nuxtApp.vueApp.component('Column', Column )
    nuxtApp.vueApp.component('ColumnGroup ', ColumnGroup  )
    nuxtApp.vueApp.component('Row ', Row  )
    nuxtApp.vueApp.component('Dialog', Dialog  )
    nuxtApp.vueApp.component('Dropdown', Dropdown   )
    nuxtApp.vueApp.component('RadioButton', RadioButton    )
    nuxtApp.vueApp.component('InputNumber', InputNumber    )
    nuxtApp.vueApp.component('Textarea', Textarea    )
    nuxtApp.vueApp.component('FileUpload', FileUpload    )
    nuxtApp.vueApp.component('Toast', Toast    )
    nuxtApp.vueApp.component('Rating', Rating    )
    nuxtApp.vueApp.component('Datatable', Datatable    )
    nuxtApp.vueApp.component('ConfirmDialog', ConfirmDialog)
  });