import { default as D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta } from "D:/APPS/mayoschic/pages/Sample-Page.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta } from "D:/APPS/mayoschic/pages/actualites/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta } from "D:/APPS/mayoschic/pages/agenda/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta } from "D:/APPS/mayoschic/pages/auth/Login.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta } from "D:/APPS/mayoschic/pages/auth/Register.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta } from "D:/APPS/mayoschic/pages/auth/forget-password.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta } from "D:/APPS/mayoschic/pages/contact.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta } from "D:/APPS/mayoschic/pages/dashboard/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta } from "D:/APPS/mayoschic/pages/home/actualites.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta } from "D:/APPS/mayoschic/pages/home/agenda.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta } from "D:/APPS/mayoschic/pages/home/incidence.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta } from "D:/APPS/mayoschic/pages/home/report.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta } from "D:/APPS/mayoschic/pages/home/signalisation.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47index_46vueMeta } from "D:/APPS/mayoschic/pages/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta } from "D:/APPS/mayoschic/pages/notifications/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta } from "D:/APPS/mayoschic/pages/policy.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta } from "D:/APPS/mayoschic/pages/privacy.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta } from "D:/APPS/mayoschic/pages/report/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta } from "D:/APPS/mayoschic/pages/schedule/[id].vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta } from "D:/APPS/mayoschic/pages/schedule/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta } from "D:/APPS/mayoschic/pages/shift-note/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta } from "D:/APPS/mayoschic/pages/shifts/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta } from "D:/APPS/mayoschic/pages/signalement/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta } from "D:/APPS/mayoschic/pages/ui/Shadow.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta } from "D:/APPS/mayoschic/pages/ui/Typography.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta } from "D:/APPS/mayoschic/pages/urgence/index.vue?macro=true";
import { default as D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta } from "D:/APPS/mayoschic/pages/utilisateurs/index.vue?macro=true";
export default [
  {
    name: D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta?.name ?? "Sample-Page",
    path: D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta?.path ?? "/Sample-Page",
    file: "D:/APPS/mayoschic/pages/Sample-Page.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47Sample_45Page_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/Sample-Page.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta?.name ?? "actualites",
    path: D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta?.path ?? "/actualites",
    file: "D:/APPS/mayoschic/pages/actualites/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47actualites_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/actualites/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta?.name ?? "agenda",
    path: D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta?.path ?? "/agenda",
    file: "D:/APPS/mayoschic/pages/agenda/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47agenda_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/agenda/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta?.name ?? "auth-Login",
    path: D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta?.path ?? "/auth/Login",
    file: "D:/APPS/mayoschic/pages/auth/Login.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47auth_47Login_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/auth/Login.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta?.name ?? "auth-Register",
    path: D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta?.path ?? "/auth/Register",
    file: "D:/APPS/mayoschic/pages/auth/Register.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47auth_47Register_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/auth/Register.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta?.name ?? "auth-forget-password",
    path: D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta?.path ?? "/auth/forget-password",
    file: "D:/APPS/mayoschic/pages/auth/forget-password.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47auth_47forget_45password_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/auth/forget-password.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta?.name ?? "contact",
    path: D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "D:/APPS/mayoschic/pages/contact.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta?.name ?? "dashboard",
    path: D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta?.path ?? "/dashboard",
    file: "D:/APPS/mayoschic/pages/dashboard/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta?.name ?? "home-actualites",
    path: D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta?.path ?? "/home/actualites",
    file: "D:/APPS/mayoschic/pages/home/actualites.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47home_47actualites_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/home/actualites.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta?.name ?? "home-agenda",
    path: D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta?.path ?? "/home/agenda",
    file: "D:/APPS/mayoschic/pages/home/agenda.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47home_47agenda_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/home/agenda.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta?.name ?? "home-incidence",
    path: D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta?.path ?? "/home/incidence",
    file: "D:/APPS/mayoschic/pages/home/incidence.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47home_47incidence_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/home/incidence.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta?.name ?? "home-report",
    path: D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta?.path ?? "/home/report",
    file: "D:/APPS/mayoschic/pages/home/report.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47home_47report_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/home/report.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta?.name ?? "home-signalisation",
    path: D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta?.path ?? "/home/signalisation",
    file: "D:/APPS/mayoschic/pages/home/signalisation.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47home_47signalisation_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/home/signalisation.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47index_46vueMeta?.name ?? "index",
    path: D_58_47APPS_47mayoschic_47pages_47index_46vueMeta?.path ?? "/",
    file: "D:/APPS/mayoschic/pages/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta?.name ?? "notifications",
    path: D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta?.path ?? "/notifications",
    file: "D:/APPS/mayoschic/pages/notifications/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47notifications_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta?.name ?? "policy",
    path: D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta?.path ?? "/policy",
    file: "D:/APPS/mayoschic/pages/policy.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47policy_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta?.name ?? "privacy",
    path: D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta?.path ?? "/privacy",
    file: "D:/APPS/mayoschic/pages/privacy.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47privacy_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta?.name ?? "report",
    path: D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta?.path ?? "/report",
    file: "D:/APPS/mayoschic/pages/report/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47report_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta?.name ?? "schedule-id",
    path: D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta?.path ?? "/schedule/:id",
    file: "D:/APPS/mayoschic/pages/schedule/[id].vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47schedule_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/schedule/[id].vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta?.name ?? "schedule",
    path: D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta?.path ?? "/schedule",
    file: "D:/APPS/mayoschic/pages/schedule/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47schedule_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta?.name ?? "shift-note",
    path: D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta?.path ?? "/shift-note",
    file: "D:/APPS/mayoschic/pages/shift-note/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47shift_45note_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/shift-note/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta?.name ?? "shifts",
    path: D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta?.path ?? "/shifts",
    file: "D:/APPS/mayoschic/pages/shifts/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47shifts_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/shifts/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta?.name ?? "signalement",
    path: D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta?.path ?? "/signalement",
    file: "D:/APPS/mayoschic/pages/signalement/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47signalement_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/signalement/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta?.name ?? "ui-Shadow",
    path: D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta?.path ?? "/ui/Shadow",
    file: "D:/APPS/mayoschic/pages/ui/Shadow.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47ui_47Shadow_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/ui/Shadow.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta?.name ?? "ui-Typography",
    path: D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta?.path ?? "/ui/Typography",
    file: "D:/APPS/mayoschic/pages/ui/Typography.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47ui_47Typography_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/ui/Typography.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta?.name ?? "urgence",
    path: D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta?.path ?? "/urgence",
    file: "D:/APPS/mayoschic/pages/urgence/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47urgence_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/urgence/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta?.name ?? "utilisateurs",
    path: D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta?.path ?? "/utilisateurs",
    file: "D:/APPS/mayoschic/pages/utilisateurs/index.vue",
    children: [],
    meta: D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta,
    alias: D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta?.alias || [],
    redirect: D_58_47APPS_47mayoschic_47pages_47utilisateurs_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/APPS/mayoschic/pages/utilisateurs/index.vue").then(m => m.default || m)
  }
]