import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const PurpleTheme: ThemeTypes = {
    name: 'PurpleTheme',
    dark: false,
    variables: {
        'border-color': '#eeeeee',
        'carousel-control-size': 10
    },
    colors: {
        primary: '#b71c1c',
        secondary: '#00a6fb',
        info: '#539BFF',
        success: '#2DC653',
        accent: '#FFAB91',
        warning: '#fb8c00',
        error: '#d81159',
        muted:'#5a6a85',
        yellowColor:'#FFD500',
        navy: '#25a18e',
        textType: '#54656F',
        blueDark:'#00296B',
        lightprimary: '#ECF2FF',
        lightsecondary: '#E8F7FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#000',
        containerBg: '#ffffff',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4'
    }
};
export { PurpleTheme};
